import * as React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import Container from "../components/container"

const ContactPage = () => (
  <Container>
    <SEO title="Contact" />
    <h1 className="font-serif dark:text-slate-100 text-4xl mt-10 mb-5 font-bold underline">Contact</h1>
    <p className="my-4">This form is for testing purposes and is not regularly monitored.</p>
    <Link to="/" className="hover:text-cyan-300">Go back to the homepage</Link>
    <div className="max-w-screen-sm mx-auto my-4">
      <form className="flex flex-col" method="post" action="/thank-you/" netlify-honeypot="bot-field" data-netlify="true" name="contact">
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <label>
          Name
          <input
            type="text"
            className="mt-1 block w-full rounded-md bg-gray-100 dark:bg-slate-900 dark:text-slate-100 border-transparent focus:border-gray-500 focus:bg-white focus:dark:bg-dark-900 focus:ring-0"
            name="name"
            id="name"
          />
        </label>
        <label>
          Email
          <input
            type="email"
            className="mt-1 block w-full rounded-md bg-gray-100 dark:bg-slate-900 dark:text-slate-100 border-transparent focus:border-gray-500 focus:bg-white focus:dark:bg-dark-900 focus:ring-0"
            name="email"
            id="email"
          />
        </label>
        <label>
          Subject
          <input
            type="text"
            className="mt-1 block w-full rounded-md bg-gray-100 dark:bg-slate-900 dark:text-slate-100 border-transparent focus:border-gray-500 focus:bg-white focus:dark:bg-dark-900 focus:ring-0"
            name="subject"
            id="subject"
          />
        </label>
        <label>
          Message
          <textarea
            className="mt-1 block w-full rounded-md bg-gray-100 dark:bg-slate-900 dark:text-slate-100 border-transparent focus:border-gray-500 focus:bg-white focus:dark:bg-dark-900 focus:ring-0"
            name="message"
            id="message"
            rows="5"
          />
        </label>
        <button type="submit" className="mt-2 bg-blue-500 hover:bg-blue-700 dark:bg-slate-500 dark:hover:bg-slate-700 text-white font-bold py-2 px-4 rounded">Send</button>
        <input type="reset" className="cursor-pointer mt-2" value="Clear" />
      </form>
    </div>
  </Container>
)

export default ContactPage
